import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './CbClient.module.css'

const CbClient = (props) => {
    const { catalogueIdentifier } = useParams()
    const { masterData, getCatalogueData } = props
    const authCtx = useContext(AuthContext)
    const [allClients, setAllClients] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [isChanging, setIsChanging] = useState(false)

    const getAllClients = async () => {
        const getAllClientsResponse = await fetch(BASE_URL_API + "/getAllClients",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllClientsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientsRespo = await getAllClientsResponse.json();
            if (getAllClientsRespo.status === 'success') {
                setAllClients(getAllClientsRespo?.response)
                setFilteredData(getAllClientsRespo?.response)
            } else {
                if (getAllClientsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllClients([])
                    setFilteredData([])
                }
            }
        }
    }

    useEffect(() => {
        getAllClients()
    }, [])

    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterData(allClients, searchKey)
    }, [searchKey])

    const updateClientToCatalogue = async (k) => {
        try {
            const response = await fetch(BASE_URL_API + "/updateClientToCatalogue", {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    catalogueIdentifier: catalogueIdentifier,
                    clientData: k
                }),
            });

            const result = await response.json();
            if (result.status === "success") {
                // setRedemptionHistory(result.response);
                setIsChanging(false)
                toast(`Client updated successfully!`)
                getCatalogueData()
            } else {
                if (result.message === "token Expired, please login again!") {
                    authCtx.logout();
                }
            }
        } catch (error) {
            console.error("Error fetching order history:", error);
        }
    };


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.header}>
                    Select A Client
                </div>
                <div className={styles.mainContent}>
                    {masterData?.clientData?.clientIdentifier === '' || isChanging === true ?
                        <div className={styles.cardWrapper}>
                            <div className={styles.search}>
                                <input placeholder="Search for client info..." value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                            </div>
                            <div>
                                <div className={styles.tableBody}>
                                    {filteredData?.map((item, index) => (
                                        <div className={styles.eachClientRow} key={index} onClick={() => updateClientToCatalogue(item)}>
                                            <div className={styles.sn}>
                                            </div>
                                            <div className={styles.brandName}>
                                                {item?.brandName}
                                            </div>
                                            <div className={styles.firmNameWrapper}>
                                                <div className={styles.firmName}>
                                                    {item?.firmName}
                                                </div>
                                                <div className={styles.officeAddress}>
                                                    {item?.officeAddress}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className={styles.thatsAll}>That's all</div>
                                </div>
                            </div>

                        </div>
                        :
                        <div className={styles.cardWrapper}>
                            <div className={styles.cardContent}>
                                <div>
                                    <div className={styles.clientIdentifier}>
                                        {masterData?.clientData?.clientIdentifier}
                                    </div>
                                    <div className={styles.clientFirmName}>
                                        {masterData?.clientData?.clientFirmName}
                                    </div>
                                    <div className={styles.clientAddress}>
                                        {masterData?.clientData?.clientAddress}
                                    </div>
                                </div>
                                <div onClick={() => setIsChanging(true)} className={styles.changeBtn}>
                                    Change
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CbClient