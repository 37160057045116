import React, { useContext } from 'react'
import { IoMdEye } from 'react-icons/io'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdDeleteForever, MdEdit } from 'react-icons/md'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './EachRowAllProducts.module.css'


const EachRowAllProducts = (props) => {
    const authCtx = useContext(AuthContext)
    const { item, editingItemHandler, isSelected, getEcomProducts, handleSelectItem, categoryData } = props
    const deleteEcomProduct = async () => {
        if (window.confirm(`Do you really want to deleted ${item?.productTitle}?`)) {
            const deleteEcomProductResponse = await fetch(BASE_URL_API + "/deleteEcomProduct",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        productIdentifier: item?.productIdentifier
                    })
                });

            if (!deleteEcomProductResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const deleteEcomProductRespo = await deleteEcomProductResponse.json()
                if (deleteEcomProductRespo.status === "success") {
                    toast(`Product deleted successfully!`);
                    getEcomProducts()
                } else {
                    if (deleteEcomProductRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {

                    }
                }
                console.log(deleteEcomProductRespo)
            }
        }
    }

    const updateProductCategory = async (k) => {
        // console.log(k, item.productIdentifier)
        const updateProductCategoryResponse = await fetch(BASE_URL_API + "/updateProductCategory",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    productIdentifier: item.productIdentifier,
                    categoryIdentifier: k
                })
            });

        if (!updateProductCategoryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateProductCategoryRespo = await updateProductCategoryResponse.json()
            if (updateProductCategoryRespo.status === "success") {
                toast(`Successfully updated the category!`)
            } else {

            }
            console.log(updateProductCategoryRespo)
        }
    }

    let subCategoryOptions = []
    if (item?.categoryIdentifier !== null) {
        subCategoryOptions = categoryData?.filter(itemsub => itemsub.categoryIdentifier === item?.categoryIdentifier)[0]?.subCategory
    }

    const updateProductSubCategory = async (k) => {
        console.log(k, item.productIdentifier)
        const updateProductSubCategoryResponse = await fetch(BASE_URL_API + "/updateProductSubCategory",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    productIdentifier: item.productIdentifier,
                    subCategoryIdentifier: k
                })
            });

        if (!updateProductSubCategoryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateProductSubCategoryRespo = await updateProductSubCategoryResponse.json()
            if (updateProductSubCategoryRespo?.status === "success") {
                toast(`Successfully updated the sub category!`)
            } else {

            }
            console.log(updateProductSubCategoryRespo)
        }
    }



    return (
        <div className={styles.mainWrapper} >
            <div className={styles.selector} onClick={() => handleSelectItem(item?.productIdentifier)} >
                {isSelected === true ?
                    <MdCheckBox size={22} />
                    :
                    <MdCheckBoxOutlineBlank size={20} />
                }
            </div>
            <div className={styles.id}>
                {item?.productIdentifier.substring(0, 3)}
            </div>
            <div className={styles.title}>
                {item?.productTitle}
            </div>
            <div className={styles.brand}>
                {item?.brandName}
            </div>
            <div className={styles.category}>
                {item?.categoryIdentifier === null &&
                    <select onChange={(e) => updateProductCategory(e.target.value)}>
                        <option>Select Category</option>
                        {categoryData?.map((itemCategory, indexCategory) => (
                            <option key={indexCategory} value={itemCategory?.categoryIdentifier} >{itemCategory?.category}</option>
                        ))}
                    </select>
                }
            </div>
            <div className={styles.category}>
                {item?.category}
            </div>
            <div className={styles.category}>
                {item?.subCategoryIdentifier === "" &&
                    <select onChange={(e) => updateProductSubCategory(e.target.value)}>
                        <option>Select Sub Category</option>
                        {subCategoryOptions?.map((itemsubCategory, indexsubCategory) => (
                            <option key={indexsubCategory} value={itemsubCategory?.subCategoryIdentifier} >{itemsubCategory?.subCategoryLabel}</option>
                        ))}
                    </select>
                }
            </div>
            <div className={styles.category}>
                {item?.subCategory}
            </div>
            {/* <div className={styles.description}
                dangerouslySetInnerHTML={{__html : item?.productDescription}}
            >
            </div> */}
            <div className={styles.pointsRequired}>
                {item?.pointsRequired}
            </div>
            <div className={styles.editBtnWrapper}>
                <button onClick={() => editingItemHandler(item)} ><MdEdit size={18} /> Edit</button>
                <button onClick={() => deleteEcomProduct()} ><MdDeleteForever size={18} /></button>
                <a href={`https://shop.qnnox.com/redeem/${item?.productIdentifier}`} target="_blank" ><IoMdEye size={18} /></a>
            </div>
        </div>
    )
}

export default EachRowAllProducts