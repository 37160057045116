import React, { useContext, useState } from 'react'
import { FaToggleOff, FaToggleOn } from 'react-icons/fa'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './CbSettings.module.css'

const CbSettings = ({ masterData, getCatalogueData }) => {
    const { catalogueIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
    const [subdomain, setSubdomain] = useState(masterData?.clientData?.subdomain)

    const updateCatalogueStatus = async (k) => {
        setIsUpdatingStatus(true)
        const response = await fetch(BASE_URL_API + "/updateCatalogueStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    catalogueIdentifier: catalogueIdentifier,
                    statusType : k
                })
            });

        if (!response.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const respo = await response.json()
            if (respo?.status === "success") {
                toast(`Successfully update the price!`)
                getCatalogueData()
            } else {
                if (respo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(respo)
                }
            }
        }
        setIsUpdatingStatus(false)
    }
    
    const createASubdomain = async () => {
        setIsUpdatingStatus(true)
        const response = await fetch(BASE_URL_API + "/updateCatalogueSubdomain",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    catalogueIdentifier: catalogueIdentifier,
                    subdomain : subdomain
                })
            });

        if (!response.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const respo = await response.json()
            if (respo?.status === "success") {
                toast(`Successfully update the price!`)
                getCatalogueData()
            } else {
                if (respo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(respo)
                }
            }
            console.log(respo)
        }
        setIsUpdatingStatus(false)
    }



    return (
        <div className={styles.mainWrapper}>

            <div>
                <div>
                    {isUpdatingStatus === false ?
                        <div onClick={() => updateCatalogueStatus('active')} className={styles.isActive} >
                            {masterData?.isActive === "1" ?
                                <FaToggleOff size={24} color="purple" />
                                :
                                <FaToggleOn size={24} color="purple" />
                            }
                            Active Catalogue
                        </div>
                        :
                        <div className={styles.isActive}>
                            Updating ...
                        </div>
                    }
                </div>

                <div className={styles.subdomainWrapper}>
                    <div>
                        <div className={styles.sdTitle}>
                            Sub Domain
                        </div>
                    </div>
                    <div className={styles.sdAction}>
                        <div className={styles.sdInput}>
                            <input placeholder="Enter subdomain name.." value={subdomain} onChange={(e) => setSubdomain(e.target.value)} />
                        </div>
                        <div className={styles.sdCreate}>
                            <button onClick={() => createASubdomain()} >Create</button>
                        </div>
                    </div>
                </div>

                <div>
                    {isUpdatingStatus === false ?
                        <div onClick={() => updateCatalogueStatus('monetization')} className={styles.isActive} >
                            Price Based
                            {masterData?.isPointsBased === "0" ?
                                <FaToggleOff size={24} color="purple" />
                                :
                                <FaToggleOn size={24} color="purple" />
                            }
                            Points Based
                        </div>
                        :
                        <div className={styles.isActive}>
                            Updating ...
                        </div>
                    }
                </div>


            </div>
        </div>
    )
}

export default CbSettings