import React, { useContext, useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './CbItems.module.css';
import MultiSelectDropdown from './MultiSelectDropdown';

const CbItems = ({ categorySubcategoryData = [], masterData, getCatalogueData }) => {
    const { catalogueIdentifier } = useParams()
    const authCtx = useContext(AuthContext);
    const [allProducts, setAllProducts] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isAdding, setIsAdding] = useState(false)
    const [controllerMode, setControllerMode] = useState('view') /// update

    console.log(masterData)

    const getEcomProducts = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(BASE_URL_API + '/getEcomProducts', {
                method: 'POST',
                body: JSON.stringify({ token: authCtx?.token }),
            });

            if (!response.ok) {
                throw new Error('Server Error');
            }
            const data = await response.json();

            if (data.status === 'success' && Array.isArray(data.response)) {
                setAllProducts(data.response);
                setFilteredData(data.response);
            } else if (data.message === 'token Expired, please login again!') {
                authCtx.logout();
            } else {
                setError('Failed to fetch products');
            }
        } catch (err) {
            setError(err.message || 'Something went wrong');
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (controllerMode === "update") {
            getEcomProducts();
        }
    }, [controllerMode]);

    const handleCategoryChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
        setSelectedCategories(selectedValues);
        setSelectedSubcategories([]); // Reset subcategories when categories change
    };

    const handleSubcategoryChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, (option) => option.value);
        setSelectedSubcategories(selectedValues);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value || '');
    };

    const handleSelectProduct = (product) => {
        if (!product || !product.productIdentifier) return;

        setSelectedProducts((prev) => {
            const isAlreadySelected = prev.some((p) => p.productIdentifier === product.productIdentifier);

            return isAlreadySelected
                ? prev.filter((p) => p.productIdentifier !== product.productIdentifier) // Remove if exists
                : [...prev, product]; // Add full product object if not selected
        });
    };


    // Convert categorySubcategoryData to a suitable format for MultiSelectDropdown
    const categoryOptions = categorySubcategoryData.map(cat => ({
        value: String(cat.categoryIdentifier),
        label: cat.category
    }));

    const subcategoryOptions = categorySubcategoryData
        .filter(cat => selectedCategories.includes(String(cat.categoryIdentifier)))
        .flatMap(cat => cat.subCategory?.map(sub => ({
            value: String(sub.subCategoryIdentifier),
            label: sub.subCategoryLabel
        })) || []);

    // Improved Filtering Logic
    useEffect(() => {
        const newFilteredProducts = allProducts.filter(product => {
            if (!product || typeof product !== 'object') return false;

            const matchesCategory =
                selectedCategories.length === 0 || selectedCategories.includes(String(product.categoryIdentifier));

            const matchesSubcategory =
                selectedSubcategories.length === 0 || selectedSubcategories.includes(String(product.subCategoryIdentifier));

            const matchesSearch =
                !searchQuery || (product.itemTitle && product.itemTitle.toLowerCase().includes(searchQuery.toLowerCase()));

            return matchesCategory && matchesSubcategory && matchesSearch;
        });

        setFilteredData(newFilteredProducts);
    }, [allProducts, selectedCategories, selectedSubcategories, searchQuery]);

    const addToCatalogueHandler = async () => {
        setIsAdding(true)
        const updateItemsToCatalogueResponse = await fetch(BASE_URL_API + "/updateItemsToCatalogue",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    catalogueIdentifier: catalogueIdentifier,
                    items: selectedProducts
                })
            });

        if (!updateItemsToCatalogueResponse.ok) {
            console.log("something went wrong : Server Error")
        } else {
            const updateItemsToCatalogueRespo = await updateItemsToCatalogueResponse.json()
            if (updateItemsToCatalogueRespo?.status === "success") {
                toast(`Successfully added products to catalogue..`)
                getCatalogueData()
            } else {
                if (updateItemsToCatalogueRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                }
            }
            console.log(updateItemsToCatalogueRespo)
        }
        setIsAdding(false)
    }

    return (
        <div className={styles.mainWrapper}>
            {error && <p className={styles.error}>{error}</p>}
            {controllerMode === "view" ?
                <div className={styles.mainContainer}>
                    {masterData?.itemsData?.length > 0 ?
                        <div className={styles.mainHeader}>
                            <div className={styles.headerRow}>
                                <div className={styles.alreadyAddedItemsTxt}>{masterData?.itemsData?.length} item(s) added!</div>
                                <div className={styles.addMoreBtn}>
                                    <button onClick={() => setControllerMode('update')}>Add More</button>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div>
                                You haven't added any product / item in the catalogue!
                            </div>
                            <div>
                                <button onClick={() => setControllerMode('update')} >Start Adding</button>
                            </div>
                        </div>
                    }
                </div>
                :
                <div className={styles.mainContainer}>
                    {isLoading ? (
                        <p>Loading products...</p>
                    ) : (
                        <>
                            <div className={styles.mainHeader}>
                                <div className={styles.headerRow}>
                                    <div className={styles.alreadyAddedItemsTxt}>Add More Products to the Catalogue
                                        {masterData?.itemsData?.length > 0 && ` | ${masterData?.itemsData?.length} item(s) already added!`}
                                    </div>
                                    <div className={styles.addMoreBtn}>
                                        <button onClick={() => setControllerMode('view')}>View Added Products</button>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.filters}>
                                <div className={styles.filterWrapper}>
                                    <MultiSelectDropdown
                                        options={categoryOptions}
                                        selectedValues={selectedCategories}
                                        setSelectedValues={setSelectedCategories}
                                        placeholder="Select Categories"
                                    />

                                    <MultiSelectDropdown
                                        options={subcategoryOptions}
                                        selectedValues={selectedSubcategories}
                                        setSelectedValues={setSelectedSubcategories}
                                        placeholder="Select Subcategories"
                                    />

                                    <input
                                        type="text"
                                        placeholder="Search Products..."
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <div className={styles.selectedItems}>
                                    {selectedProducts?.length} / {filteredData?.length} items selected
                                    {isAdding === false ?
                                        <button onClick={() => addToCatalogueHandler()} className={styles.addToCatalogueBtn} >Add To Catalogue</button>
                                        :
                                        <button className={styles.addToCatalogueBtn} ><FaSpinner className={styles.spinner} /> Adding Items...</button>
                                    }
                                </div>
                            </div>

                            {filteredData.length === 0 ? (
                                <p>No results found</p>
                            ) : (
                                <div className={styles.table}>
                                    <div className={styles.eachProductTitle}>
                                        <div style={styles.productIdentifier} >
                                            <input
                                                type="checkbox"
                                                onChange={() =>
                                                    setSelectedProducts(filteredData.map(p => p.productIdentifier || ''))
                                                }
                                            />
                                        </div>
                                        <div className={styles.productTitle} >Product Name</div>
                                        <div className={styles.categoryIdentifier}>Category</div>
                                        <div className={styles.subCategoryIdentifier}>Subcategory</div>
                                        <div className={styles.subCategoryIdentifier}>Action</div>

                                    </div>
                                    <div className={styles.rowWrapper}>
                                        {filteredData.map(product => (
                                            <div key={product.itemIdentifier || Math.random()} className={styles.eachProductRow}
                                                onClick={() => handleSelectProduct(product)}
                                            >
                                                <div key={product.productIdentifier} >
                                                    {selectedProducts.some((p) => p.productIdentifier === product.productIdentifier) ? (<MdCheckBox size={20} />) : (<MdCheckBoxOutlineBlank size={20} />)}
                                                    {product.name}
                                                </div>
                                                <div className={styles.productTitle} >{product.productTitle || 'No title available'}</div>
                                                <div className={styles.categoryIdentifier}>{product.categoryIdentifier || 'No category'}</div>
                                                <div className={styles.subCategoryIdentifier}>{product.subCategoryIdentifier || 'No subcategory'}</div>
                                                <div className={styles.subCategoryIdentifier}>
                                                    <a target="_blank" href={`https://shop.qnnox.com/redeem/${product?.productIdentifier}`}>View</a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            }
        </div>
    );
};

export default CbItems;
