import React, { useContext, useState } from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import styles from './CreateNewCatalogue.module.css'
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import AuthContext from '../../Store/auth-context';
import { BASE_URL_API } from '../../References/urls';

const CreateNewCatalogue = (props) => {
    const authCtx = useContext(AuthContext)
    const { isOpen, onClose, onSuccess } = props
    const [shouldTakeToConfig, setShouldTakeToConfig] = useState(true)
    const [isCreating, setIsCreating] = useState(false)
    const [catalogueName, setCatalogueName] = useState('')
    const [remarks, setRemarks] = useState('')
    const nav = useNavigate()

    const createNewCatalogue = async () => {
        setIsCreating(true)
        try {
            if (catalogueName.trim() !== '') {
                const response = await fetch(BASE_URL_API + "/createNewCatalogue", {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        name: catalogueName,
                        remarks: remarks
                    }),
                });

                const result = await response.json();
                if (result.status === "success") {
                    toast(`Successfully created the catalogue.`)
                    onClose()
                    if(shouldTakeToConfig) {
                        nav(`/catalogue/${result?.response?.catalogueIdentifier}`)
                    } else {
                        onSuccess()
                    }
                } else {
                    if (result.message === "token Expired, please login again!") {
                        authCtx.logout();
                    }
                }
                console.log(result)
            } else {
                alert("Please give it a name..")
            }
        } catch (error) {
            console.error("Error fetching order history:", error);
        }
        setIsCreating(false)
    };



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Create New Catalogue
                        </div>
                        <div className={styles.mainBody}>
                            <div className={styles.nameWrapper}>
                                <input
                                    autoFocus
                                    value={catalogueName}
                                    placeholder="Let's give it a name"
                                    onChange={(e) => setCatalogueName(e.target.value)}
                                />
                            </div>
                            <div className={styles.remarksWrapper}>
                                <textarea
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                    placeholder="Add some remarks for your reference purpose in the future (optional)"
                                >
                                </textarea>
                            </div>

                        </div>
                        <div className={styles.mainFooter}>
                            <div className={styles.footerContainer}>
                                <button className={styles.cancelBtn} onClick={() => onClose()} >Cancel</button>
                                <div className={styles.createBtnWrapper}>
                                    <div className={styles.configTxt} onClick={() => setShouldTakeToConfig(!shouldTakeToConfig)} >
                                        {shouldTakeToConfig === true ?
                                            <MdCheckBox size={18} />
                                            :
                                            <MdCheckBoxOutlineBlank size={18} />
                                        }
                                        Take to configuration page post creation
                                    </div>
                                    {isCreating === true ?
                                        <button className={styles.createBtn}><CgSpinner size={18} className={styles.spinner} />Creating...</button>
                                        :
                                        <button className={styles.createBtn} onClick={() => createNewCatalogue()} >Create</button>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        )
    }

}

export default CreateNewCatalogue