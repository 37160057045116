import React, { useContext, useEffect, useState } from 'react'
import { MdArrowBack } from 'react-icons/md'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import CbClient from '../../Components/Catalogue/CbClient'
import CbHome from '../../Components/Catalogue/CbHome'
import CbItems from '../../Components/Catalogue/CbItems'
import CbPoints from '../../Components/Catalogue/CbPoints'
import CbPricing from '../../Components/Catalogue/CbPricing'
import CbSettings from '../../Components/Catalogue/CbSettings'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './CatalogueBuilder.module.css'

const CatalogueBuilder = () => {
    const { catalogueIdentifier, step } = useParams()
    const authCtx = useContext(AuthContext)
    const [categorySubcategoryData, setCategorySubcategoryData] = useState([])

    const [masterData, setMasterData] = useState({
        catalogueIdentifier : catalogueIdentifier,
        clientData : {
            clientIdentifier : ''
        }
    })

    const getCatalogueData = async() => {
        try {
            const response = await fetch(BASE_URL_API + "/getCatalogueData", {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    catalogueIdentifier : catalogueIdentifier,
                }),
            });

            const result = await response.json();
            if (result.status === "success") {
                setMasterData(result.response)
            } else {
                if (result.message === "token Expired, please login again!") {
                    authCtx.logout();
                }
            }
        } catch (error) {
            console.error("Error fetching order history:", error);
        }
    }

    useEffect(() => {
        getCatalogueData()
    }, [])


    const getCategoriesSubcategoriesData = async () => {
        try {
            const response = await fetch(`${BASE_URL_API}/getCategoriesSubcategoriesData`, {
                method: 'POST',
                body: JSON.stringify({ token: authCtx.token }),
            });
            const result = await response.json();
            if (result.status === 'success') {
                setCategorySubcategoryData(result.response);
            } else {
                if (result.message === "token Expired, please login again!") {
                    authCtx.logout()
                }
                console.error('Error fetching data:', result);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        getCategoriesSubcategoriesData();
    }, []);



    
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.navWrapper}>
                <Link to={`/catalogues`}>
                    <MdArrowBack size={24} color="white" />
                </Link>
                <div className={styles.navOptionsWrapper}>
                    <Link to={`/catalogue/${catalogueIdentifier}/client`}>
                        <div className={styles.eachNavOption} >
                            <div></div>
                            <div className={styles.navTitle}>Client</div>
                        </div>
                    </Link>
                    <Link to={`/catalogue/${catalogueIdentifier}/items`}>
                        <div className={styles.eachNavOption} >
                            <div></div>
                            <div className={styles.navTitle}>Products</div>
                        </div>
                    </Link>
                    <Link to={`/catalogue/${catalogueIdentifier}/pricing`}>
                        <div className={styles.eachNavOption} >
                            <div></div>
                            <div className={styles.navTitle}>Pricing</div>
                        </div>
                    </Link>
                    <Link to={`/catalogue/${catalogueIdentifier}/points`}>
                        <div className={styles.eachNavOption} >
                            <div></div>
                            <div className={styles.navTitle}>Points</div>
                        </div>
                    </Link>
                    <Link to={`/catalogue/${catalogueIdentifier}/settings`}>
                        <div className={styles.eachNavOption} >
                            <div></div>
                            <div className={styles.navTitle}>Settings</div>
                        </div>
                    </Link>
                    <Link to={`/catalogue/${catalogueIdentifier}/design`}>
                        <div className={styles.eachNavOption} >
                            <div></div>
                            <div className={styles.navTitle}>Design</div>
                        </div>
                    </Link>
                </div>

            </div>
            <div className={styles.contentWrapper}>
                {step === 'client' && <CbClient masterData={masterData} getCatalogueData={() => getCatalogueData()}/>}
                {step === 'items' && <CbItems masterData={masterData}  getCatalogueData={() => getCatalogueData()} categorySubcategoryData={categorySubcategoryData} />  }
                {step === 'pricing' && <CbPricing masterData={masterData}  getCatalogueData={() => getCatalogueData()}/>}
                {step === 'points' && <CbPoints masterData={masterData}  getCatalogueData={() => getCatalogueData()}/>}
                {step === 'settings' && <CbSettings masterData={masterData}  getCatalogueData={() => getCatalogueData()}/>}

            </div>
            {/* <div className={styles.previewWrapper}>
                <div className={styles.previewContainer}>
                    <div className={styles.preivewCardWrapper}>
                        hi
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default CatalogueBuilder