import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { FiPlus } from 'react-icons/fi';
import MainHeader from '../../Components/Headers/MainHeader';
import styles from './CatalogueHome.module.css';
import CreateNewCatalogue from '../../Components/Catalogue/CreateNewCatalogue';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import { Link } from 'react-router-dom';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa6';

const CatalogueHome = () => {
    const authCtx = useContext(AuthContext)
    const [catalogues, setCatalogues] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredCatalogues, setFilteredCatalogues] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const [isFetchingData, setIsFetchingData] = useState(false)

    useEffect(() => {
        const filteredData = catalogues.filter(item =>
            Object.values(item).some(val =>
                String(val).toLowerCase().includes(search.toLowerCase())
            )
        );
        setFilteredCatalogues(filteredData);
    }, [search, catalogues]);

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredCatalogues.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredCatalogues.length / rowsPerPage);
    const [isCreatingNew, setIsCreatingNew] = useState(false)

    const getAllCatalogues = async () => {
        setIsFetchingData(true)
        try {
            const response = await fetch(BASE_URL_API + "/getAllCatalogues", {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                }),
            });

            const result = await response.json();
            if (result.status === "success") {
                setCatalogues(result?.response);
                setFilteredCatalogues(result?.response);
            } else {
                if (result.message === "token Expired, please login again!") {
                    authCtx.logout();
                }
            }
            console.log(result)

        } catch (error) {
            console.error("Error fetching order history:", error);
        }
        setIsFetchingData(false)
    };

    useEffect(() => {
        getAllCatalogues()
    }, [])



    return (
        <div className={styles.container}>
            <MainHeader />
            <CreateNewCatalogue isOpen={isCreatingNew} onClose={() => setIsCreatingNew(false)} onSuccess={() => getAllCatalogues()} />
            <div className={styles.mainWrapper}>
                <div className={styles.headerSection}>
                    <div className={styles.headerTitle} >Manage Catalogues</div>
                    <motion.button
                        className={styles.createButton}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => setIsCreatingNew(true)}
                    >
                        <FiPlus /> Create New Catalogue
                    </motion.button>
                </div>
                <input
                    type="text"
                    className={styles.searchInput}
                    placeholder="Search catalogues..."
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Client Name</th>
                            <th>Created On</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map(row => (
                            <tr key={row.catalogueIdentifier}>
                                <td>{row.name}</td>
                                <td>{row.clientFirmName}</td>
                                <td>{row.createdOn}</td>
                                <td>
                                    {row.isActive === "1" ?
                                        <FaToggleOff size={24} />
                                        :
                                        <FaToggleOn size={24} />
                                    }
                                </td>
                                <td>
                                    <Link to={`/catalogue/${row.catalogueIdentifier}`}>
                                        View
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className={styles.pagination}>
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span>Page {currentPage} of {totalPages}</span>
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CatalogueHome;