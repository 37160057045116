import React, { useContext, useEffect, useState } from 'react';
import { FaPlus, FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import MainHeader from '../../Components/Headers/MainHeader';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './CategoryManager.module.css';

const CategoryManager = () => {
    const authCtx = useContext(AuthContext);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [newSubcategory, setNewSubcategory] = useState('');
    const [categoryEditingSub, setCategoryEditingSub] = useState(null);
    const [isAddingNewCategoryOpen, setIsAddingNewCategoryOpen] = useState(false)

    const [activeCategory, setActiveCategory] = useState(null)
    const [activeSubcategory, setActiveSubcategory] = useState(null)
    const [isUpdatingSubcategory, setIsUpdatingSubcategory] = useState(false)

    const [isAddingNewSubcategory, setIsAddingNewSubcategory] = useState(false)

    const getCategoriesSubcategoriesData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${BASE_URL_API}/getCategoriesSubcategoriesData`, {
                method: 'POST',
                body: JSON.stringify({ token: authCtx.token }),
            });
            const result = await response.json();
            if (result.status === 'success') {
                setAllData(result.response);
            } else {
                if (result.message === "token Expired, please login again!") {
                    authCtx.logout()
                }
                console.error('Error fetching data:', result);
            }
        } catch (error) {
            console.error('Error:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        getCategoriesSubcategoriesData();
    }, []);

    const handleAddCategory = async () => {
        if (!newCategory) return;
        try {
            const response = await fetch(`${BASE_URL_API}/addCategory`, {
                method: 'POST',
                body: JSON.stringify({ token: authCtx.token, category: newCategory }),
            });
            const result = await response.json();
            if (result.status === 'success') {
                getCategoriesSubcategoriesData();
                setNewCategory('');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const subCategoryHandler = (k) => {
        console.log(k)
        setActiveSubcategory(k)
    }

    const updateSubCategoryHandler = async () => {
        console.log(activeSubcategory)
        setIsUpdatingSubcategory(true)
        const updateSubcategoryResponse = await fetch(BASE_URL_API + "/updateSubcategory",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    subcategoryData: activeSubcategory
                })
            });

        if (!updateSubcategoryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateSubcategoryRespo = await updateSubcategoryResponse.json()
            if (updateSubcategoryRespo.status === "success") {
                getCategoriesSubcategoriesData()
                toast(`Successfully updated the record!`)
                setActiveSubcategory(null)
            } else {
                if (updateSubcategoryRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                }
            }
            console.log(updateSubcategoryRespo)
        }
        setIsUpdatingSubcategory(false)
    }

    const deleteSubcategoryHandler = async () => {
        if (window.confirm(`Are you sure you want to delete the sub - category?`)===true) {
            const deleteSubcategoryResponse = await fetch(BASE_URL_API + "/deleteSubcategory",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        subCategoryIdentifier: activeSubcategory?.subCategoryIdentifier
                    })
                });

            if (!deleteSubcategoryResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const deleteSubcategoryRespo = await deleteSubcategoryResponse.json()
                if (deleteSubcategoryRespo.status === "success") {
                    toast(`Successfully delete the booking!`)
                    getCategoriesSubcategoriesData();
                    setActiveSubcategory(null)
                } else {
                    if (deleteSubcategoryRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {

                    }
                }
                console.log(deleteSubcategoryRespo)
            }
        }
    }

    const initateNewSubcategoryAdd = async (k) => {
        setIsAddingNewSubcategory(true) 
        setActiveCategory(k)
        console.log(k)
        // setActiveSubcategory(k)

        // setActiveSubcategory(null)
    }

    const addNewSubcategoryHandler = async () => {
        const addNewSubcategoryResponse = await fetch(BASE_URL_API+"/addSubcategory",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                categoryIdentifier : activeCategory.categoryIdentifier,
                category : activeCategory.category,
                subcategory : newSubcategory
            })
        })

        if(!addNewSubcategoryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addNewSubcategoryRespo = await addNewSubcategoryResponse.json()
            if(addNewSubcategoryRespo.status==="success") {
                toast(`Successfully added new subcategory!`)
                setIsAddingNewSubcategory(false)
                setActiveCategory(null)
                getCategoriesSubcategoriesData()
            } else {
                if(addNewSubcategoryRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(addNewSubcategoryRespo)
        }
    }


    return (
        <div>
            <MainHeader />
            <div className={styles.container}>
                <div className={styles.pageHeaderWrapper}>
                    <div>
                        Category Manager
                    </div>
                    <div>
                        {/* <button className={styles.addButton} onClick={() => setIsAddingNewCategoryOpen(true)}>
                            <FaPlus /> Add Category
                        </button> */}
                    </div>
                </div>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <div>
                        {isAddingNewCategoryOpen === true &&
                            <div className={styles.newCategoryWrapper} >
                                <div className={styles.newCategoryContainer}>
                                    <div className={styles.newCategoryContent}>
                                        <div className={styles.nccHeader}>
                                            Add New Category
                                        </div>
                                        <div className={styles.nccBody}>
                                            <input
                                                type="text"
                                                value={newCategory}
                                                onChange={(e) => setNewCategory(e.target.value)}
                                                placeholder="Enter new category"
                                                className={styles.nccbInputField}
                                            />
                                        </div>
                                        <div className={styles.nccFooter}>
                                            <button onClick={() => setIsAddingNewCategoryOpen(false)} className={styles.nccfCancel} >
                                                Cancel
                                            </button>
                                            <button className={styles.nccfSubmit} >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        {isAddingNewSubcategory === true &&
                            <div className={styles.newCategoryWrapper} >
                                <div className={styles.newCategoryContainer}>
                                    <div className={styles.newCategoryContent}>
                                        <div className={styles.nccHeader}>
                                            Add New Subcategory under {activeCategory?.category}
                                        </div>
                                        <div className={styles.nccBody}>
                                            <input
                                                type="text"
                                                value={newSubcategory}
                                                onChange={(e) => setNewSubcategory(e.target.value)}
                                                placeholder="Enter new sub category"
                                                className={styles.nccbInputField}
                                            />
                                        </div>
                                        <div className={styles.nccFooter}>
                                            <button onClick={() => {
                                                setIsAddingNewSubcategory(false)
                                                setActiveCategory(null)
                                            }} 
                                            className={styles.nccfCancel} >
                                                Cancel
                                            </button>
                                            <button className={styles.nccfSubmit} onClick={() => addNewSubcategoryHandler()} >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {activeSubcategory !== null &&
                            <div className={styles.subcategoryEditorWrapper} >
                                <div className={styles.subcategoryEditorContainer} >
                                    <div className={styles.subcategoryEditorContent}>
                                        <div className={styles.nccHeader}>
                                            {activeSubcategory?.categoryLabel}
                                        </div>
                                        <div className={styles.inputWrapper} >
                                            <input className={styles.nccbInputField}
                                                autoFocus={true}
                                                placeholder="Enter subcategory"
                                                value={activeSubcategory?.subCategoryLabel}
                                                onChange={(e) => setActiveSubcategory(prev => ({
                                                    ...prev,
                                                    subCategoryLabel: e.target.value
                                                }))}

                                            />
                                        </div>
                                        <div className={styles.sewFooter}>
                                            <div className={styles.actionWrapper} >
                                                <button onClick={() => deleteSubcategoryHandler()} className={styles.deleteSubcategoryBtn} >Delete</button>
                                                {isUpdatingSubcategory === true ?
                                                    <button disabled className={styles.updateBtn} >Updating...</button>
                                                    :
                                                    activeSubcategory?.subCategoryLabel?.trim() !== '' ?
                                                        <button onClick={() => updateSubCategoryHandler()} className={styles.updateBtn} >Update</button>
                                                        :
                                                        <button disabled className={styles.updateBtn} >Update</button>
                                                }
                                            </div>
                                            <div>
                                                <button className={styles.cancelBtn} onClick={() => setActiveSubcategory(null)} >Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={styles.categoriesWrapper}>
                            {allData.map(category => (
                                <div key={`${category.categoryIdentifier}-${category.ID}`} className={styles.categoryBlock}>
                                    <div className={styles.categoryHeaderWrapper}>
                                        <div className={styles.categoryHeader}>
                                            {category.category}
                                        </div>
                                    </div>
                                    <div className={styles.subcategories}>
                                        {category.subCategory.map(sub => (
                                            <div key={sub.subCategoryIdentifier} className={styles.subCategoryCard}
                                                onClick={() => subCategoryHandler(sub)}
                                            >
                                                <span>{sub.subCategoryLabel}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.totalCountWrapper}>
                                        <div className={styles.subcategoryCount} >
                                            <div>
                                                Total &nbsp;: {category?.subCategory?.length}
                                            </div>
                                            <div className={styles.addNewCategory}>
                                                <button onClick={() => initateNewSubcategoryAdd(category)} >Add New +</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CategoryManager;