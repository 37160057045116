import React, { useContext, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './EditProduct.module.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles

const EditProduct = (props) => {
    const { isOpen, item, closeEditingItem, onCompletion } = props;
    const authCtx = useContext(AuthContext);
    console.log(item);

    const [title, setTitle] = useState(item?.productTitle || '');
    const [description, setDescription] = useState(item?.productDescription || '');
    const [pointsRequired, setPointsRequired] = useState(item?.pointsRequired || '');
    const [stock, setStock] = useState(item?.stock || '');
    const [brand, setBrand] = useState(item?.brandName || '');
    const [category, setCategory] = useState(item?.category || '');
    const [thumbnail, setThumbnail] = useState(item?.thumbnail || '');
    const [isUpdating, setIsUpdating] = useState(false);

    const updateProductHandler = async () => {
        setIsUpdating(true);
        try {
            const response = await fetch(`${BASE_URL_API}/updateEcomProduct`, {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    productId: item?.productIdentifier,
                    title,
                    description,
                    pointsRequired,
                    stock,
                    brand,
                    category,
                    thumbnail,
                }),
            });

            const result = await response.json();

            if (result.status === 'success') {
                onCompletion();
                closeEditingItem();
            } else {
                if (result.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    alert('Could not update the item records, please try again!');
                }
            }
        } catch (error) {
            console.error('Error updating product:', error);
        }
        setIsUpdating(false);
    };

    if (!isOpen) return null;

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.pageTitle}>Edit Item</div>
                <div className={styles.editingFormWrapper}>
                    <div className={styles.leftFormWrapper}>
                        <div className={styles.title}>
                            <div className={styles.titleCaption}>Product Title</div>
                            <input placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className={styles.description}>
                            <div className={styles.titleCaption}>Product Description</div>
                            {/* <CKEditor
                                editor={ClassicEditor}
                                data={description}
                                onChange={(event, editor) => setDescription(editor.getData())}
                            /> */}
                            <ReactQuill value={description} onChange={setDescription} />

                        </div>
                        <div className={styles.twoInRow}>
                            <div className={styles.pointsRequired}>
                                <div className={styles.titleCaption}>Points Required</div>
                                <input placeholder="Points" value={pointsRequired} onChange={(e) => setPointsRequired(e.target.value)} />
                            </div>
                            <div className={styles.stock}>
                                <div className={styles.titleCaption}>Stock</div>
                                <input placeholder="Stock" value={stock} onChange={(e) => setStock(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightFormWrapper}>
                        <div className={styles.category}>
                            <div className={styles.titleCaption}>Category</div>
                            <input placeholder="Category" value={category} onChange={(e) => setCategory(e.target.value)} />
                        </div>
                        <div className={styles.thumbnail}>
                            <div className={styles.titleCaption}>Thumbnail</div>
                            <input placeholder="Thumbnail" value={thumbnail} onChange={(e) => setThumbnail(e.target.value)} />
                        </div>
                        <div className={styles.actionWrapper}>
                            <div className={styles.cancelBtn}>
                                <button onClick={closeEditingItem}>Cancel</button>
                            </div>
                            <div className={styles.saveBtn}>
                                <button onClick={updateProductHandler} disabled={isUpdating}>
                                    {isUpdating ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProduct;