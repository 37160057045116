import React, { useContext, useEffect, useState } from 'react';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import { FaSpinner } from 'react-icons/fa6';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './CbPoints.module.css';

const CbPoints = ({ masterData, getCatalogueData }) => {
    const { catalogueIdentifier } = useParams()
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [percentage, setPercentage] = useState(1);
    const [incrementalType, setIncrementalType] = useState('higher');
    const [roundOff, setRoundOff] = useState(false);
    const authCtx = useContext(AuthContext)
    const [isUpdatingPrices, setIsUpdatingPrices] = useState(false)

    const selectItemHandler = (product) => {
        if (!product || !product.productIdentifier) return;

        setSelectedProducts((prev) => {
            const isAlreadySelected = prev.some((p) => p.productIdentifier === product.productIdentifier);
            return isAlreadySelected
                ? prev.filter((p) => p.productIdentifier !== product.productIdentifier)
                : [...prev, { ...product, pointsRequired: product.pointsRequired || product.offeredPrice }];
        });
    };

    const handlePriceChange = (productIdentifier, newPrice) => {
        setSelectedProducts((prev) =>
            prev.map((item) =>
                item.productIdentifier === productIdentifier
                    ? { ...item, pointsRequired: newPrice }
                    : item
            )
        );
    };

    const priceUpdationHandler = () => {
        if (selectedProducts.length === 0) return;

        setSelectedProducts((prev) =>
            prev.map((product) => {
                const offeredPrice = parseFloat(product.offeredPrice);

                if (percentage === 0) {
                    return { ...product, pointsRequired: offeredPrice }; // Reset to offered price
                }

                const pointsValue = offeredPrice / percentage
                let updatedPrice = pointsValue;

                updatedPrice = roundOff ? Math.round(updatedPrice) : parseFloat(updatedPrice.toFixed(2));

                return {
                    ...product,
                    pointsRequired: Math.max(0, updatedPrice),
                };
            })
        );
    };


    const updateNowHandler = async () => {
        console.log(selectedProducts)
        setIsUpdatingPrices(true)
        const response = await fetch(BASE_URL_API + "/updateItemsPointsInCatalogue",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    itemsData: selectedProducts,
                    catalogueIdentifier: catalogueIdentifier
                })
            });

        if (!response.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const respo = await response.json()
            if (respo?.status === "success") {
                toast(`Successfully update the price!`)
                getCatalogueData()
                setSelectedProducts([])
            } else {
                if (respo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(respo)
                }
            }
        }
        setIsUpdatingPrices(false)
    };

    useEffect(() => {
        priceUpdationHandler();
    }, [selectedProducts.length, percentage, incrementalType, roundOff]);

    const selectAllItemsHandler = () => {
        if (selectedProducts?.length > 0) {
            setSelectedProducts([])
        } else {
            setSelectedProducts(masterData?.itemsData)
        }
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>Update the points for each product in the catalogue!</div>
                <div className={styles.mainContent}>
                    <div className={styles.dataWrapper}>
                        {masterData?.itemsData?.length > 0 ? (
                            <div className={styles.tableWrapper}>
                                <div className={styles.productHeader}>
                                    <div className={styles.checkBox} onClick={() => selectAllItemsHandler()}>
                                        {selectedProducts.length === masterData?.itemsData?.length ?
                                            <MdCheckBox size={20} />
                                            :
                                            <MdCheckBoxOutlineBlank size={20} />
                                        }
                                    </div>
                                    <div className={styles.productTitle}>Product Title</div>
                                    <div className={styles.basePrice}>Base Price</div>
                                    <div className={styles.basePrice}>Offered Price</div>
                                    <div className={styles.basePrice}>Points</div>
                                </div>
                                <div className={styles.productWrapper}>
                                    {masterData.itemsData.map((item, index) => (
                                        <div key={index} className={styles.eachRow}>
                                            <div className={styles.checkBox} onClick={() => selectItemHandler(item)}>
                                                {selectedProducts.some((p) => p.productIdentifier === item.productIdentifier) ? (
                                                    <MdCheckBox size={20} />
                                                ) : (
                                                    <MdCheckBoxOutlineBlank size={20} />
                                                )}
                                            </div>
                                            <div className={styles.productTitle} onClick={() => selectItemHandler(item)}>
                                                {item.productTitle}
                                            </div>
                                            <div className={styles.basePrice}>{item.mrp}</div>
                                            <div className={styles.basePrice}>
                                                {item?.offeredPrice}
                                            </div>
                                            <div className={styles.offeredPrice}>
                                                <input
                                                    type="number"
                                                    value={
                                                        selectedProducts.find((p) => p.productIdentifier === item.productIdentifier)
                                                            ?.pointsRequired || item.pointsRequired
                                                    }
                                                    onChange={(e) => handlePriceChange(item.productIdentifier, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div>You haven't selected any item for this catalogue!</div>
                        )}
                    </div>
                    <div className={styles.actionWrapper}>
                        <div className={styles.actionWrapperHeader} >Selected: {selectedProducts.length}</div>
                        <div className={styles.formulaWrapper}>
                            <div className={styles.offeredPriceTxt}>Set Points Value</div>

                            <div className={styles.valPoints}>
                                <div className={styles.rupeeIcon}>
                                    ₹1
                                </div>
                                =
                                <div className={styles.percentage}>
                                    <input
                                        type="number"
                                        value={percentage}
                                        onChange={(e) => setPercentage(Math.max(0, e.target.value))}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className={styles.actionBtns}>
                            <div className={styles.roundOffWrapper}>
                                <button onClick={() => setRoundOff(!roundOff)}>
                                    {roundOff ? <BsToggleOn size={24} color="purple" /> : <BsToggleOff color="purple" size={24} />}
                                    {roundOff ? 'Disable Round Off' : 'Enable Round Off'}
                                </button>
                            </div>
                            <div>
                                {isUpdatingPrices === false ?
                                    <button onClick={updateNowHandler} disabled={selectedProducts.length === 0} className={styles.updateCatalogueNow} >
                                        Update Catalogue Now
                                    </button>
                                    :
                                    <button disabled={true} className={styles.updateCatalogueNow} >
                                        <FaSpinner className={styles.spinner} size={16} /> Updating prices...
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CbPoints;
