import React, { useState, useEffect, useRef } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { motion } from 'framer-motion';
import styles from './MultiSelectDropdown.module.css';

const MultiSelectDropdown = ({ options, selectedValues, setSelectedValues, placeholder = "Select..." }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const dropdownRef = useRef(null);

    // Toggle selection of an item
    const toggleSelection = (value) => {
        setSelectedValues(prev =>
            prev.includes(value) ? prev.filter(v => v !== value) : [...prev, value]
        );
    };

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div className={styles.dropdownContainer} ref={dropdownRef}>
            <div className={styles.dropdownHeader} onClick={() => setIsOpen(!isOpen)}>
                <span>{selectedValues.length ? selectedValues.length + " selected" : placeholder}</span>
                <motion.div animate={{ rotate: isOpen ? 180 : 0 }} className={styles.arrow}></motion.div>
            </div>

            {isOpen && (
                <motion.div
                    initial={{ opacity: 0, scaleY: 0 }}
                    animate={{ opacity: 1, scaleY: 1 }}
                    exit={{ opacity: 0, scaleY: 0 }}
                    className={styles.dropdownList}
                >
                    <input
                        type="text"
                        className={styles.searchBox}
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value.toLowerCase())}
                    />
                    <div className={styles.itemsList}>
                        {options
                            .filter(option => option.label.toLowerCase().includes(searchText))
                            .map(option => (
                                <div key={option.value} className={styles.item} onClick={() => toggleSelection(option.value)}>
                                    {selectedValues.includes(option.value) ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
                                    <span>{option.label}</span>
                                </div>
                            ))}
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
